import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY
} from "configs/AppConfig";
import Loading from "components/shared-components/Loading";
import { verifyToken } from "services/appServices/auth";
import { loadOrganizations } from "services/appServices/organization";
import { getOrgList, orgLoad } from "store/slices/organizationSlice";
import { loadProfileDetails } from "services/appServices/profile";
import { profile } from "store/slices/profileSlice";
import { mannualConfig } from "configs/MannualConfig";

const ProtectedRoute = () => {
  const [authenticated, setAuthenticated] = useState({
    loaded: false,
    verified: false,
  });
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth_token");
  const orgUrl = localStorage.getItem("orguuid")
  const location = useLocation();
  const permissions = useSelector(({ role }) => role.data)?.permissions || [];
  
  useEffect(() => {
    verifyToken(setAuthenticated);
  }, []);

  useEffect(() => {
    if(token){dispatch(orgLoad(false));
    loadOrganizations(
      { limit: 9999, offset: 0, keyword: "", sortQuery: "" },
      () => {}
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getOrgList(res.data.data?.docs));
        dispatch(orgLoad(true));
      } else {
        dispatch(getOrgList([]));
        dispatch(orgLoad(true));
      }
    });}
  }, [token]);
  
  const pathKey = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const pathRole = Object.keys(mannualConfig).find(item  => (
  	mannualConfig[item].routeName === pathKey
  ))

  useEffect(() => {
    loadProfileDetails().then((res) => {
      if (res.status === 200) {
        dispatch(profile(res.data.data));
      }
    });
  }, [pathRole]);

  if (pathRole) {
    const routePermission = permissions.find(item => item.menu === pathRole)
    if (routePermission && !routePermission.action.list) {
      return (
        <Navigate
          to={`o/${orgUrl}/dashboard`}
          replace
        />
      );
    }
  }

  if (!token) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

  if (!authenticated.loaded) {
    return <Loading />;
  }

  return <Outlet />;
};

export default ProtectedRoute;

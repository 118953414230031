import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOrganizationChange: false
}

export const changeOrganizationSlice = createSlice({
	name: 'organization',
	initialState,
	reducers: {
        changeOrganization: (state, action) => {
           state.isOrganizationChange =  action.payload
        }
	},
})

export const { changeOrganization } = changeOrganizationSlice.actions
export default changeOrganizationSlice.reducer
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    user: {}
}

const profileSlice = createSlice({
	name: 'profileData',
	initialState,
	reducers: {
        changeProfile: (state, action) => {
			state.user = action.payload.user
		},
		profile: (state, action) => {
			state.user = action.payload
		}
	},
})

export const { changeProfile, profile } = profileSlice.actions

export default profileSlice.reducer
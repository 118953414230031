import { apiRequest } from "utils/async/apiUtils";

export const loadOrganizations = async (params, setList = () => {}, setLoaded = () => {}, setCount = () => {}) => {
  setLoaded(false)
  try {
    const res = await apiRequest('get', 'user-organization/list', {
      queries: {
        limit: params.limit,
        offset: params.offset,
        keyword: params.keyword,
        sortQuery: params.sortQuery,
        region: params.region ? params.region : '',
        status: params.status ? params.status : ''
      },
    });
  
    const data = res.data;
  
    setList(data.data.docs);
    setCount(data.data.totalDocs)
    setLoaded(true)
    return res
  } catch (err) {
    setLoaded(true)
    return err.response
  }  
};

export const loadOrganizationDetails = async (id, setDetails = () => {}) => {
  try {
    const res = await apiRequest(
      'get',
      `user-organization/details/${id}`,
    )
  
    const data = res.data
  
    setDetails(data.data)
  } catch (err) {
    throw new Error(err.message)
  }
}

export const addOrganization = async params => {
  try {
    const res = await apiRequest('post', 'user-organization/add', {
      body: {
        title: params.title,
        region: params.region,
        timeZone: params.timeZone
      }
    })
  
    const data = res.data
    return data
  } catch (err) {
    return err.response
  }
}

export const updateOrganization = async (id, params) => {
  try {
    const res = await apiRequest('put', 'organization/edit', {
      body: {
        id,
        title: params.title,
        region: params.region
      }
    })
  
    const data = res.data
  
    return data.data
  } catch (err) {
    throw new Error(err.message)
  }
}

export const deleteOrganization = async (ids = []) => {
  try {
    await apiRequest('delete', 'organization/delete', {
      body: { id: ids }
    })
    
  } catch (err) {
    throw new Error(err.message)
  }
}

export const orderOrganization = async (data = [], offset) => {
  try {
    await apiRequest(
      'put',
      'organization/save-ordering',
      { body: data }
    )
  } catch (err) {
    throw new Error(err.message)
  }
}

export const statusChangeOrganization = async (ids = [], status) => {
  try {
    const res = await apiRequest("put", "organization/change-status", {
      body: {
        id: ids,
        status: status,
      },
    });
    const data = res.data;
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
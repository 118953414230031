export const mannualConfig = {
    dashboard: {
        routeName: "dashboard"
    },
    workOrders: {
        routeName: "work-order"
    },
    myOrders: {
        routeName: "my-order"
    },
    workRequests: {
        routeName: "work-request"
    },
    customers: {
        routeName: "customer"
    },
    locations: {
        routeName: "location"
    },
    assets: {
        routeName: "asset"
    },
    assetTags: {
        routeName: "asset-tag"
    },
    vendors: {
        routeName: "vendor"
    },
    procedures: {
        routeName: "procedure"
    },
    users: {
        routeName: "user"
    },
    roles: {
        routeName: "role"
    },
    logs: {
        routeName: "userLog"
    },
    billing: {
        routeName: "billing"
    },
    report: {
        routeName: "report"
    },
    setting: {
        routeName: "setting"
    }
}
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth)
  
	return token ? window.location.href=AUTHENTICATED_ENTRY : <Outlet/>
}

export default PublicRoute
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {}
}

export const roleSlice = createSlice({
	name: 'role',
	initialState,
	reducers: {
        setRole: (state, action) => {
           state.data =  action.payload
        }
	},
})

export const { setRole } = roleSlice.actions
export default roleSlice.reducer
import { apiRequest } from "utils/async/apiUtils";

export const PermissionList = async (params) => {
  try {
    const res = await apiRequest("get", `role-wise-permission/list/${params.organization}`);
    const data = res.data;
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgId: localStorage.getItem("orguuid"),
  organizationList: [],
  selectedOrganization: {},
  loaded: true,
};

const organizationSlice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    getOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    getOrgList: (state, action) => {
      state.organizationList = action.payload;
    },
    orgLoad: (state, action) => {
      state.loaded = action.payload;
    },
  },
});

export const { getOrgId, getOrgList, orgLoad, orgIdLoaded } =
  organizationSlice.actions;
export default organizationSlice.reducer;

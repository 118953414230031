import { apiRequest } from "utils/async/apiUtils";

export const loadProfileDetails = async (setDetails = () => {}) => {
  try {
    const res = await apiRequest("get", `profile/details`);

    const data = res.data;
    setDetails(data.data);
    return res;
  } catch (err) {
    return err.response;
  }
};
export const tokenExpire = async () => {
  try {
    const res = await apiRequest("get", `profile/details`);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const updateProfile = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      "profile/update",
      {
        body: {
          title: params.title,
          firstName: params.firstName,
          lastName: params.lastName,
          email: params.email,
          idCode: params.idCode,
          // password: params.password,
          image: params.image,
          nationality: params.nationality,
          timezone: params.timezone,
          language: params.language,
        },
      },
      "form-data"
    );

    const data = res.data;
    return data;
  } catch (error) {
    return error.response;
  }
};

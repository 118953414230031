import React from 'react'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'user-login',
        path: `${AUTH_PREFIX_PATH}/user-login`,
        component: React.lazy(() => import('views/app-views/apps/common/userLogin'))
    },

    {
        key: 'verify-user-login',
        path: `verify-user-login`,
        component: React.lazy(() => import('views/auth-views/authentication/verify-login')),
    },

    {
        key: 'verify-admin-login',
        path: `verify-admin-login`,
        component: React.lazy(() => import('views/auth-views/authentication/verify-login')),
    },

    {
        key: 'login-1',
        path: `${AUTH_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'login-2',
        path: `${AUTH_PREFIX_PATH}/login-2`,
        component: React.lazy(() => import('views/auth-views/authentication/login-2')),
    },
    {
        key: 'register-1',
        path: `${AUTH_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'register-2',
        path: `${AUTH_PREFIX_PATH}/register-2`,
        component: React.lazy(() => import('views/auth-views/authentication/register-2')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    }
]

export const protectedRoutes = [

    // org select
    {
        key: 'dashboard-list',
        path: `/o/select`,
        component: React.lazy(() => import('views/app-views/apps/dashboard/dashboard-list/index')),
        menuCode: 'dashboard'
    },
    {
        key: 'apps.DashboardList',
        path: `/o/:uuid/dashboard`,
        component: React.lazy(() => import('views/app-views/apps/DashboardList/dashboardList')),
    },
    // Users
    {
        key: 'apps-users-and-role-user-list',
        path: `/o/:uuid/user`,
        component: React.lazy(() => import('views/app-views/apps/users/user-list')),
        menuCode: 'users'
    },
    {
        key: 'apps-users-and-role-user-list.user-list',
        path: `/o/:uuid/user/list`,
        component: React.lazy(() => import('views/app-views/apps/users/user-list')),
        menuCode: 'users'
    },

    // Role
    {
        key: 'apps.users-and-role-user-list',
        path: `/o/:uuid/role`,
        component: React.lazy(() => import('views/app-views/apps/roles/role-list')),
        menuCode: 'roles'
    },
    {
        key: 'apps.users-and-role-user-list.roles-add',
        path: `/o/:uuid/role/add`,
        component: React.lazy(() => import('views/app-views/apps/roles/role-add/index')),
        menuCode: 'roles'
    },
    {
        key: 'apps.users-and-role-user-list.roles-edit',
        path: `/o/:uuid/role/edit/:id`,
        component: React.lazy(() => import('views/app-views/apps/roles/role-edit/index')),
        menuCode: 'roles'
    },
    {
        key: 'apps.users-and-role-user-list.roles-detail',
        path: `/o/:uuid/role/details/:id`,
        component: React.lazy(() => import('views/app-views/apps/roles/roleDetails')),
        menuCode: 'roles'
    },
    {
        key: 'apps.users-and-role-user-list.roles-list',
        path: `/o/:uuid/role/list`,
        component: React.lazy(() => import('views/app-views/apps/roles/role-list')),
        menuCode: 'roles'
    },

    // UserLog 
    {
        key: 'apps.users-and-role-user-list',
        path: `/o/:uuid/userLog`,
        component: React.lazy(() => import('views/app-views/apps/userLog/userLog-list')),
        menuCode: 'logs'
    },
    {
        key: 'apps.users-and-role-user-list.userLogs-list',
        path: `/o/:uuid/userLog/list`,
        component: React.lazy(() => import('views/app-views/apps/userLog/userLog-list')),
        menuCode: 'logs'
    },

    // Billing 
    {
        key: 'apps.billing',
        path: `/o/:uuid/billing`,
        component: React.lazy(() => import('views/app-views/apps/billing/billing-list')),
        menuCode: 'logs'
    },
    {
        key: 'apps.billing.billing-list',
        path: `/o/:uuid/billing/list`,
        component: React.lazy(() => import('views/app-views/apps/billing/billing-list')),
        menuCode: 'logs'
    },

    // Reports 
    {
        key: 'apps.report',
        path: `/o/:uuid/report`,
        component: React.lazy(() => import('views/app-views/apps/report/report-list')),
    },
    {
        key: 'apps.report.report-list',
        path: `/o/:uuid/report/list`,
        component: React.lazy(() => import('views/app-views/apps/report/report-list')),
    },

    // Setting 
    {
        key: 'apps.setting',
        path: `/o/:uuid/setting`,
        component: React.lazy(() => import('views/app-views/apps/setting/setting-list')),
    },
    {
        key: 'apps.setting.setting-list',
        path: `/o/:uuid/setting/list`,
        component: React.lazy(() => import('views/app-views/apps/setting/setting-list')),
    },

    // Assets 
    {
        key: 'apps.assets',
        path: `/o/:uuid/asset`,
        component: React.lazy(() => import('views/app-views/apps/assets/asset-list')),
        menuCode: 'assets'
    },
    {
        key: 'apps.assets.asset-list',
        path: `/o/:uuid/asset/list`,
        component: React.lazy(() => import('views/app-views/apps/assets/asset-list')),
        menuCode: 'assets'
    },

    // procedure

    {
        key: 'apps.procedures',
        path: `/o/:uuid/procedure`,
        component: React.lazy(() => import('views/app-views/apps/procedures/procedure-list')),
        menuCode: 'procedures'
    },
    {
        key: 'apps.procedures.procedure-list',
        path: `/o/:uuid/procedure/list`,
        component: React.lazy(() => import('views/app-views/apps/procedures/procedure-list')),
        menuCode: 'procedures'
    },
    {
        key: 'apps.procedures.procedure-add',
        path: `/o/:uuid/procedure/add`,
        component: React.lazy(() => import('views/app-views/apps/procedures/procedure-add/index')),
        menuCode: 'procedures'
    },
    {
        key: 'apps.procedures.procedure-edit',
        path: `/o/:uuid/procedure/edit`,
        component: React.lazy(() => import('views/app-views/apps/procedures/procedure-edit/index')),
        menuCode: 'procedures'
    },

    // work request

    {
        key: 'apps.workRequests',
        path: `/o/:uuid/work-request`,
        component: React.lazy(() => import('views/app-views/apps/workRequests/request-list')),
        menuCode: 'workRequests'
    },
    {
        key: 'apps.workRequests.request-details',
        path: `/o/:uuid/work-request/details/:id`,
        component: React.lazy(() => import('views/app-views/apps/workRequests/request-details')),
        menuCode: 'workRequests'
    },
    {
        key: 'apps.workRequests.request-list',
        path: `/o/:uuid/work-request/list`,
        component: React.lazy(() => import('views/app-views/apps/workRequests/request-list')),
        menuCode: 'workRequests'
    },


    // asset tag

    {
        key: 'apps.assetTags',
        path: `/o/:uuid/asset-tag`,
        component: React.lazy(() => import('views/app-views/apps/assetTag/assetTag-list')),
        menuCode: 'assetTags'
    },
    {
        key: 'apps.assetTag.assetTag-list',
        path: `/o/:uuid/asset-tag/list`,
        component: React.lazy(() => import('views/app-views/apps/assetTag/assetTag-list')),
        menuCode: 'assetTags'
    },

//Location

    {
        key: 'apps.locations',
        path: `/o/:uuid/location`,
        component: React.lazy(() => import('views/app-views/apps/locations/location-list')),
        menuCode: 'locations'
    },
    {
        key: 'apps.locations.location-list',
        path: `/o/:uuid/location/list`,
        component: React.lazy(() => import('views/app-views/apps/locations/location-list')),
        menuCode: 'locations'
    },

//Customer

    {
        key: 'apps.customers',
        path: `/o/:uuid/customer`,
        component: React.lazy(() => import('views/app-views/apps/customers/customer-list')),
        menuCode: 'customers'
    },
    {
        key: 'apps.customers.customer-list',
        path: `/o/:uuid/customer/list`,
        component: React.lazy(() => import('views/app-views/apps/customers/customer-list')),
        menuCode: 'customers'
    },

//Vendor

    {
        key: 'apps.vendors',
        path: `/o/:uuid/vendor`,
        component: React.lazy(() => import('views/app-views/apps/vendors/vendor-list')),
        menuCode: 'vendors'
    },
    {
        key: 'apps.vendors.vendor-list',
        path: `/o/:uuid/vendor/list`,
        component: React.lazy(() => import('views/app-views/apps/vendors/vendor-list')),
        menuCode: 'vendors'
    },

    // work order
    {
        key: 'apps.workOrder',
        path: `/o/:uuid/work-order`,
        component: React.lazy(() => import('views/app-views/apps/workOrder/workOrderList')),
        menuCode: 'workOrders'
    },
    {
        key: 'apps.workOrder',
        path: `/o/:uuid/work-order/my-order`,
        component: React.lazy(() => import('views/app-views/apps/myOrder/myOrderList')),
        menuCode: 'workOrders'
    },
    {
        key: 'apps.workOrder.workOrderDetails',
        path: `/o/:uuid/work-order/details/:id`,
        component: React.lazy(() => import('views/app-views/apps/workOrder/workOrderDetails')),
        menuCode: 'workOrders'
    },

    {
        key: 'pages',
        path: `/pages`,
        component: React.lazy(() => import('views/app-views/pages')),
    },
    {
        key: 'pages.profile',
        path: `/o/:uuid/profile`,
        component: React.lazy(() => import('views/app-views/pages/profile')),
    },
    {
        key: 'pages.changePassword',
        path: `/o/:uuid/changePassword`,
        component: React.lazy(() => import('views/app-views/pages/password')),
    },
    {
        key: 'pages.invoice',
        path: `/o/:id/pages/invoice`,
        component: React.lazy(() => import('views/app-views/pages/invoice')),
    },
    {
        key: 'pages.setting',
        path: `/o/:id/pages/setting/*`,
        component: React.lazy(() => import('views/app-views/pages/setting')),
    },
    {
        key: 'pages.user-list',
        path: `/o/:id/pages/user-list`,
        component: React.lazy(() => import('views/app-views/pages/user-list')),
    },
    
]

export const middlewareRoutes = [
    {
        key: 'apps.supportLogin.support-list',
        path: `support-login`,
        component: React.lazy(() => import('views/app-views/apps/supportLogin/support-list')),
    },
    {
        key: 'apps.accept.accept-user',
        path: `accept-user`,
        component: React.lazy(() => import('views/app-views/apps/accept/accept-user')),
    },
    {
        key: 'apps.accept.reject.reject-user',
        path: `reject-user`,
        component: React.lazy(() => import('views/app-views/apps/accept/reject/reject-user')),
    },
    {
        key: 'apps.error-page.error-page-1',
        path: `error-1`,
        component: React.lazy(() => import('views/app-views/apps/error-page/error-page-1')),
    },
    {
        key: 'apps.error-page.error-page-2',
        path: `error-2`,
        component: React.lazy(() => import('views/app-views/apps/error-page/error-page-2')),
    }
]
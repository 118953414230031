import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import IntlMessage from "components/util-components/IntlMessage";
let url = "/o/select";
// if(localStorage.getItem("orguuid")){
// url = `/o/${localStorage.getItem("orguuid")}/dashboard`
// }

export const APP_NAME = "inLoop";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = url;
export const UNAUTHENTICATED_ENTRY = "/login";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};

export const cloudinaryConfig = {
  cloud_name: "dop8490w6",
  api_key: "736877694562612",
  upload_preset: "glppl4ok",
};

export const orgByTimeZone = (params, timezone) => {
  let requestedDueDate = params;
  let gmtOffsetName = timezone;
  let date = new Date(requestedDueDate);
  let offsetSign = gmtOffsetName.includes("+") ? 1 : -1;
  let offsetHours = parseInt(gmtOffsetName.slice(4, 6));
  let offsetMinutes = parseInt(gmtOffsetName.slice(7, 9));
  date.setHours(date.getHours() + offsetSign * offsetHours);
  date.setMinutes(date.getMinutes() + offsetSign * offsetMinutes);
  var adjustedTimeZone = date.toISOString();
  const newDate = adjustedTimeZone.split("T")[0]
  return newDate;
};

export const Locale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

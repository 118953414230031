import axios from "axios"
import { API_BASE_URL } from "constants/ApiConstant"
import { apiRequest } from "utils/async/apiUtils"

export const authLogin = async (params, setMessage=() => {}, setErrType=() => {}, setIsDiaable=() => {}) => {
  try {
    const res = await axios.post(
      API_BASE_URL + 'user-login',
      {
          email: params.email,
      }
    )

    return res.data
  } catch (err) {
    setMessage(err.response.data.message)
    setErrType("error")
    setIsDiaable(true);
    setTimeout(() => {
      setIsDiaable(false)
    }, 3000);
    throw new Error(err.message)
  }
}



export const verifyToken = async (setAuth = () => {}) => {
  try {
    setAuth({ loaded: false, authenticated: false })

    const res = await apiRequest(
      'post',
      'verify-token',
      {}
    )

    const data = res.data
    setAuth({ loaded: true, authenticated: true })

    return data
  } catch (err) {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('refreshToken')

    window.location.href = '/auth/login'
    
    throw new Error(err.message)
  }
}